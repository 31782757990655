import React from "react";
import path from "path";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import Card from "../components/Card";
import { Box, Button, ButtonBase, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import theme from "../style/theme";
import { useHasScroll } from "has-scroll-hook";
//import homeImage from "../../../src/images/home-bg.jpg";
import homeImageTR from "../../../src/images/istanbul-skyline-2.jpg";
import passportImageTR from "../../../src/images/passport-hand2.png";

import homeImageDR from "../../../src/images/dr-beach.jpg";

import homeImageSKN from "../../../src/images/skn-landscape.jpg";
import passportImageSKN from "../../../src/images/skn-passport.png";

// import { setupI18n } from "../i18n";
// import { useTranslation } from "react-i18next";

function imagesByCountry(country) {
  const countries = {
    turkey: { homeImage: homeImageTR, passportImage: passportImageTR },
    dr: { homeImage: homeImageDR },
    skn: { homeImage: homeImageSKN, passportImage: passportImageSKN },
    default: { homeImage: homeImageDR }
  };
  return countries[country] || countries.default
};

const useStyles = makeStyles(theme => ({
  heroDescription: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "45px"
    },
    color: theme.palette.primary.main,
    fontWeight: "normal",
    fontFamily:
      "Work Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif",
    margin: "0 auto",
    maxWidth: "800px"
  },
  heroDescriptionHighlight: {
    background: "rgba(255,255,255,0.65)"
  },
  passportImage: {
    [theme.breakpoints.down("sm")]: {
      bottom: "0px"
    },
    position: "absolute",
    left: "0px",
    bottom: "0px",
    height: "300px",
    zIndex: "-1"
  },
  passportImageSKN: {
    [theme.breakpoints.down("sm")]: {
      bottom: "0px"
    },
    position: "absolute",
    left: "0px",
    bottom: "0px",
    zIndex: "-1"
  }
}));

const Posts = ({ posts, pathPrefix }) => {
  return (
    <Grid container spacing={3}>
      {posts.map(
        ({
          node: {
            excerpt,
            fileAbsolutePath,
            frontmatter: { id, title, featuredImage, slug }
          }
        }) => {
          const postDate = path
            .basename(fileAbsolutePath)
            .split("-")
            .splice(0, 3)
            .join("-");
          return (
            <Grid item xs={12} sm={4} key={id}>
              <Card
                featuredImage={featuredImage}
                title={title}
                url={`/${pathPrefix}/${slug}`}
                postDate={postDate}
                excerpt={excerpt}
              />
            </Grid>
          );
        }
      )}
    </Grid>
  );
};

export default function HomeTemplate(props) {
  /* Get the vertical scrollbar offset as a boolean value. */
  const {
    data: {
      site: {
        siteMetadata: {
          // title,
          /*
          templates: {
            posts: { pathPrefix }
          }
          */
          subsites
        }
      },
      allMdx: { edges: posts }
    },
    pageContext: { subsite }
  } = props;
  // setupI18n(subsite);

  const hasScroll = useHasScroll();
  const classes = useStyles();
  const { description, prefixPath } = subsite;
  const pathPrefix = `${prefixPath}/posts/`;

  const {
    components: {
      appbar: { cta }
    },
    country
  } = subsites.find(s => s.key === subsite.key);

  const { homeImage, passportImage } = imagesByCountry(country);

  return (
    <Layout elevateAppBar={hasScroll} subsite={subsite}>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          //backgroundColor: theme.palette.primary.main,
          backgroundColor: "#6dbaff",
          clipPath: "polygon(0 0, 100% 0, 100% 60%, 0% 100%)",
          WebkitClipPath: "polygon(0 0, 100% 0, 100% 60%, 0% 100%)",
          marginBottom: "-30px"
        }}
      >
        <Box
          textAlign="center"
          paddingTop={4}
          paddingBottom={12}
          paddingX={8}
          style={{
            //backgroundColor: theme.palette.primary.main,
            background: `
              linear-gradient(
                rgba(255, 255, 255, 0.5),
                rgba(255, 255, 255, 0.5)
              ),
              url(${homeImage})
            `,
            backgroundRepeat: "no-repeat",
            color: theme.palette.common.white,
            position: "relative",
            clipPath: "polygon(0 0, 100% 0, 100% 60%, 0% 100%)"
          }}
        >
          <Box marginBottom={0}>
            <Typography
              color="inherit"
              variant="h2"
              className={classes.heroDescription}
              // style={}
            >
              <span className={classes.heroDescriptionHighlight}>
                {description}
              </span>
            </Typography>
          </Box>
          {passportImage && (
            <img
              className={classes[`passportImage${country.toUpperCase()}`] || classes.passportImage}
              src={passportImage}
              alt={`${country} passport`}
            />
          )}
        </Box>
      </Box>
      <Box flexGrow={1} marginX="auto" width="100%" maxWidth={960}>
        <Box padding={2} paddingTop={-4} style={{ textAlign: "center" }}>
          {cta.steps.map((step, i) => (
            <Typography
              key={i}
              variant="h5"
              style={{
                marginBottom: "4px"
              }}
            >
              <span
                style={{
                  backgroundColor: "rgba(138, 102, 61, 0.05)",
                  // backgroundColor: "rgba(1, 94, 73, 0.05)",
                  padding: "6px"
                }}
              >
                <strong>{i + 1}.</strong> {step}
              </span>
            </Typography>
          ))}
        </Box>
        <Box padding={2} paddingTop={-4} style={{ textAlign: "center" }}>
          <Button
            component={Link}
            to={cta.url}
            // classes={{ root: classes.ctaButton }}
            variant="contained"
            color="primary"
            size="large"
          >
            {cta.consultationTitle}
          </Button>
        </Box>
      </Box>
      <Box flexGrow={1} marginX="auto" width="100%" maxWidth={960}>
        <Box padding={2}>
          <Posts posts={posts} pathPrefix={pathPrefix} />
          {posts.length > 1 && (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding={1}
              marginTop={1}
            >
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to={`/${pathPrefix}/page/1`}
              >
                View All
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($limit: Int!, $blogPostsRegex: String) {
    site {
      siteMetadata {
        title
        description
        templates {
          posts {
            pathPrefix
          }
        }
        subsites {
          country
          key
          components {
            appbar {
              cta {
                consultationTitle
                url
                steps
              }
            }
          }
        }
      }
    }
    allMdx(
      filter: { fileAbsolutePath: { regex: $blogPostsRegex } }
      sort: { order: DESC, fields: [fileAbsolutePath] }
      limit: $limit
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          fileAbsolutePath
          frontmatter {
            id
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
